import React from "react";
import { AudioPresets, ConnectionState, Track, VideoPresets } from "livekit-client";
import {
  CarouselView,
  TrackRefContext,
  useRoomContext,
  useTrackToggle,
  ParticipantContextIfNeeded,
} from "@livekit/components-react";
import { setupDisconnectButton } from "@livekit/components-core";
import { BackgroundBlur } from "@livekit/track-processors";
import { UserImage } from "@hiyllo/omni-images";

import {
  Stardate,
  StardateLogKind,
  StardateSourceEnum,
} from "@hiyllo/stardate";
import { Modal } from "@hiyllo/ux/modal";

import * as StartRecordingBP from "../../../blueprints/meet/start-event-meeting-recording";

import { motion } from "framer-motion";
import { seamlessClient } from "../../../seamless-client";
import {
  faDisplaySlash,
  faMicrophone,
  faMicrophoneSlash,
  faTimes,
  faVideo,
  faVideoSlash,
  faSquareUser as faSquareUserHollow,
  faPhone,
  faAlarmClock,
  faUserCircle,
  faHammerBrush,
} from "@fortawesome/pro-light-svg-icons";
import { ContinuityMeetingContext } from "../../../main/meeting-provider";
import "@livekit/components-styles";
import {
  isTrackReference,
  isEqualTrackRef,
} from "@livekit/components-core";
import { styled } from "@hiyllo/ux/styled";
import { CircleButton } from "@hiyllo/ux/circle-button";
import {
  faCaretDown,
  faCaretUp,
  faSquareUser,
} from "@fortawesome/pro-solid-svg-icons";
import {
  useHoverTriggeredWrappedPopOver,
  useWrappedPopOver,
} from "@hiyllo/ux/context-menu";
import { Card } from "@hiyllo/ux/surface";
import { TrackTile } from "./components/track-tile";
import { useSelfMaybe } from "@hiyllo/omni-continuity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type CalendarEvent } from "../../../types/calendar/calendar-event";
import { ScreenshareIcon } from "@hiyllo/icons";
import { type AlertType } from "../../../types/alerts/alert";
import { Features } from "../../../types/navigation/features";
import { useMarkAlertsRead } from "../../alerts/hooks/use-mark-alerts-read";
import {
  type AlertReceivedHandler,
  useAlertsContext,
} from "@hiyllo/omni-alerts/main";
import { PillButton } from "@hiyllo/ux/pill-button";
import { Tenant } from "../../../platform/tenancy";
import { SHAKE_KEYFRAME } from "./consts";
import { IS_BETA_ENV } from "../../../platform/xp";
import { doesAlertLinkTo } from "../../../platform/alerts/helpers/alert-links-to";
import {
  MeetingEventProvider,
  StartedContext,
  useMeetingEvent,
} from "./meeting-event-provider";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { GridTrackRenderer } from "./components/grid-layout";
import { type HiylloMeetParticiantIdentityType } from "../../../types/meet";
import { getRootURL } from "../../../platform/environment/get-root-url";
import { Electron } from "../../../platform/electron";
import { DisconnectedIndicator } from "../ui/disconnected-indicator";
import { MeetingTimer } from "../ui/meeting-timer";
import { WaitingRoom } from "./waiting-room";
import { useTracks } from "../lib/use-tracks";
import { MeetingCanvas } from "../providers/meeting-canvas";
import { useStartScreenShare } from "../v2/hooks/start-screen-share";

const PageContainer = styled("div", ({ $theme }) => ({
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  background: $theme.background1,
  color: $theme.foreground,
  position: "relative",
  fontFamily: "hiyllo",
}));

const ControlsBar = styled<"div", { isExternal: boolean }>(
  "div",
  ({ isExternal }) => ({
    display: "flex",
    flexDirection: "row",
    gap: 20,
    justifyContent: "space-between",
    padding: 20,
    paddingLeft: isExternal ? 20 : 0,
    alignItems: "center",
  }),
);

const ControlsBarButtons = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 20,
  justifyContent: "center",
});

const MeetingButtonTooltip = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  color: $theme.foreground,
  width: 175,
  borderRadius: 10,
  paddingTop: 7.5,
  paddingBottom: 7.5,
  textAlign: "center",
}));

const ControlsBarRight = styled("div", {
  flexGrow: 0,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 10,
});

const VideoMeetingLeftTitle = styled("div", ({ $theme }) => ({
  marginLeft: window.innerWidth < window.innerHeight ? 10 : 0,
  width: window.innerWidth < window.innerHeight ? "" : "calc(25vw - 30px)",
  padding: 15,
  display: "flex",
  flexDirection: "row",
  gap: 10,
  background: $theme.midground,
  borderRadius: 10,
  userSelect: "none",
  cursor: "pointer",
  justifyContent: "space-between",
}));

const VideoMeetingDetailsContainer = styled("div", ({ $theme }) => ({
  position: "absolute",
  bottom: 15,
  width: 400,
  height: 500,
  background: $theme.midground,
  borderRadius: 10,
  padding: 20,
  overflowY: "auto",
  color: $theme.foreground,
  fontFamily: "hiyllo",
}));

function useIsAtTime(event?: CalendarEvent | null): boolean {
  const [time, setTime] = React.useState(new Date());

  React.useEffect(() => {
    const inv = setInterval(() => {
      setTime(new Date());
    }, 5000);

    return () => {
      clearInterval(inv);
    };
  }, []);

  // console.log(event, time, event.timing._computed.end);

  return event != null && time >= event.timing._computed.end;
}

const AtTimeContainer = styled("div", ({ $theme }) => ({
  position: "absolute",
  left: 0 + 20,
  bottom: 90 + 20,
  background: $theme.midground,
  color: $theme.foreground,
  borderRadius: 10,
  padding: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontFamily: "hiyllo",
}));

const VideoMeetingLeftBar = React.memo(
  function VideoMeetingLeftBar(): JSX.Element {
    const { current } = React.useContext(ContinuityMeetingContext);
    const room = useRoomContext();
    const participants = [room.localParticipant, ...room.participants.values()];

    if (
      current?.videoMeeting == null ||
      !("eventUUID" in current.videoMeeting)
    ) {
      throw new Error("Invariant E245");
    }

    const eventUUID = current.videoMeeting.eventUUID;

    const event = useMeetingEvent();
    const { open, ref, CXMenuContainer, isOpen } = useWrappedPopOver({
      offset: {
        top: 0,
        left: 0,
      },
      displayMode: "fixed",
      disableMaxHeight: true,
    });
    const isAtTime = useIsAtTime(event);
    const markAlertsRead = useMarkAlertsRead();
    const onAlertReceived = React.useCallback(
      (alert?: AlertType) => {
        if (
          alert != null &&
          (doesAlertLinkTo(alert, {
            feature: Features.meet,
            params: {
              view: "event",
              eventUUID,
              password: current.videoMeeting?.meetingPassword ?? "",
            },
          }) ||
            doesAlertLinkTo(alert, {
              feature: Features.calendar,
              params: {
                view: "event",
                eventUUID,
              },
            }))
        ) {
          void markAlertsRead.call({ alertUUIDs: [alert.uuid] });
        }
      },
      [current.videoMeeting.meetingPassword, eventUUID, markAlertsRead],
    );
    const { useOnAlertReceived } = useAlertsContext();
    useOnAlertReceived(onAlertReceived as AlertReceivedHandler);

    if ((event?.title ?? current?.label) == null) {
      return (
        <div style={{ width: "calc(25% - 30px)", padding: 15 }}>
          <FontAwesomeIcon icon={faPhone} />
        </div>
      );
    }

    return (
      <>
        {isAtTime ? (
          <AtTimeContainer>
            <motion.div
              animate={{
                translateX: SHAKE_KEYFRAME,
              }}
              transition={{
                repeat: Infinity,
                repeatDelay: 1,
                duration: 0.5,
              }}
            >
              <FontAwesomeIcon icon={faAlarmClock} />
            </motion.div>
            &nbsp; At Time
          </AtTimeContainer>
        ) : null}
        <VideoMeetingLeftTitle
          _ref={ref as React.RefObject<HTMLDivElement>}
          onClick={isOpen ? close : open}
        >
          <div>{event?.title ?? current?.label}</div>
          {event != null ? (
            isOpen ? (
              <FontAwesomeIcon icon={faCaretDown} style={{ paddingTop: 4 }} />
            ) : (
              <FontAwesomeIcon icon={faCaretUp} style={{ paddingTop: 4 }} />
            )
          ) : null}
          <CXMenuContainer withAnimatePresence>
            <motion.div
              initial={{ y: 25, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 25, opacity: 0 }}
              transition={{ ease: "easeInOut" }}
            >
              <VideoMeetingDetailsContainer>
                <div style={{ fontSize: 24 }}>{event?.title}</div>
                <div
                  style={{
                    fontSize: 16,
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  {event?.description}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 20,
                    fontFamily: "hiyllo",
                    fontWeight: "bold",
                  }}
                >
                  Attendees
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    paddingTop: 5,
                  }}
                >
                  {participants.map((participant) => {
                    if (!participant.identity.startsWith("{")) return null;

                    const identity: HiylloMeetParticiantIdentityType =
                      JSON.parse(participant.identity);

                    return (
                      <div
                        key={participant.sid}
                        style={{
                          fontSize: 16,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        {identity.type === "user" ? (
                          <UserImage userId={identity.userId} width={20} />
                        ) : (
                          <div
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 20 / 4,
                              fontSize: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FontAwesomeIcon icon={faUserCircle} />
                          </div>
                        )}
                        {identity.name}
                      </div>
                    );
                  })}
                </div>
              </VideoMeetingDetailsContainer>
            </motion.div>
          </CXMenuContainer>
        </VideoMeetingLeftTitle>
      </>
    );
  },
);

const MeetingTimerWrapper = React.memo(
  function MeetingTimerWrapper(): JSX.Element | null {
    const startDate = React.useContext(StartedContext);
    const event = useMeetingEvent();

    if (event != null) {
      return (
        <MeetingTimer
          startDate={event.timing._computed.start}
          endDate={event.timing._computed.end}
        />
      );
    }

    return <MeetingTimer startDate={startDate ?? new Date()} />;
  },
);

const MeetingButton = React.memo(function MeetingButton(
  props: React.ComponentProps<typeof CircleButton> & { tooltip: string },
): JSX.Element {
  const { HoverTriggerContainer, CXMenuContainer } =
    useHoverTriggeredWrappedPopOver({
      offset: { x: 0, y: -45 },
      fadeAnimation: true,
      delay: 0,
    });

  return (
    <>
      <HoverTriggerContainer>
        <CircleButton {...props} />
      </HoverTriggerContainer>
      <CXMenuContainer>
        <MeetingButtonTooltip>{props.tooltip}</MeetingButtonTooltip>
      </CXMenuContainer>
    </>
  );
});

const stardate = new Stardate(
  StardateSourceEnum.frontendWeb,
  "omni",
  "video-meeting-ui",
  Tenant,
);

export const VideoMeetingUI = React.memo(
  function VideoMeetingUI(props: {
    spectator?: boolean;
    started?: Date;
  }): JSX.Element {
    const { current } = React.useContext(ContinuityMeetingContext);
    const tracks = useTracks();
    const screenShareTracks = tracks
      .filter(isTrackReference)
      .filter((track) => track.publication.source === Track.Source.ScreenShare && track.publication.trackName !== "screenshare/background");
    const focusTrack = screenShareTracks[0];
    const carouselTracks = tracks.filter(
      (track) => !isEqualTrackRef(track, focusTrack),
    );
    const event = useMeetingEvent();
    const [isRecording, setIsRecording] = React.useState<boolean>(
      (event?.recordingActive != null) || current?.isRecording === true || false,
    );
    const room = useRoomContext();
    const historicConnectedRef = React.useRef<boolean>(false);
    const connected = room.state === ConnectionState.Connected;

    // Logs
    React.useEffect(() => {
      stardate.log({
        kind: StardateLogKind.info,
        message: `Video Meeting State: ${room.state}`,
        data: {
          roomState: room.state,
        },
      });
    }, [room.state]);

    // Logs
    React.useEffect(() => {
      if (connected && !historicConnectedRef.current) {
        stardate.log({
          kind: StardateLogKind.trace,
          message: `Connected to Video Meeting`,
        });
        historicConnectedRef.current = true;
      }
    }, [connected]);

    const CameraToggle = useTrackToggle({ source: Track.Source.Camera });
    const MicrophoneToggle = useTrackToggle({
      source: Track.Source.Microphone,
    });
    const ScreenShareToggle = useTrackToggle({
      source: Track.Source.ScreenShare,
      captureOptions: { audio: true },
    });
    const startScreenShareWeb = useStartScreenShare();
    const [electronCaptureSources, setElectronCaptureSources] = React.useState<{
      name: string;
      id: string;
      thumbnailURL: string;
      isEmpty: boolean;
    }[] | null>(null);

    const startScreenShare = React.useCallback(async (params: {
      sourceId?: string;
      isBackground?: boolean;
    }) => {
      if (Electron.isElectron && params.sourceId == null) {
        const sources = await Electron.callAPI("getSources");
        console.log('>>> sources', sources);
        setElectronCaptureSources(sources);
      }
      else {
        // @ts-expect-error ---
        window.__hiylloScreenShareSourceId = params.sourceId;
        setElectronCaptureSources(null);
        await startScreenShareWeb({
          resolution: VideoPresets.h2160,
          audio: params.isBackground === true ? {
            channelCount: 2,
            echoCancellation: false,
            noiseSuppression: false,
          } : true,
        }, {
          audioPreset: params.isBackground === true ? AudioPresets.musicHighQualityStereo : undefined,
          dtx: !params.isBackground,
          red: !params.isBackground,
          name: params.isBackground === true ? "screenshare/background" : "screenshare/primary"
        });
      }
    }, [startScreenShareWeb]);

    const Disconnect = setupDisconnectButton(room);
    const isBlurActive = CameraToggle.track?.videoTrack?.getProcessor() != null;
    const { open, close, ref, isOpen, CXMenuContainer } = useWrappedPopOver({
      offset: {
        bottom: 120,
        right: 370,
      },
    });
    const startRecordingMutation =
      seamlessClient.useMutation<StartRecordingBP.Plug>(
        StartRecordingBP,
      );
    const eventUUID =
      current?.videoMeeting != null && "eventUUID" in current.videoMeeting
        ? current.videoMeeting.eventUUID
        : null;

    const startRecording = React.useCallback(async () => {
      if (eventUUID == null) {
        return;
      }
      await startRecordingMutation.call({
        eventUUID,
      });
    }, [eventUUID, startRecordingMutation]);

    const enableBackgroundBlur = async (): Promise<void> => {
      const videoTrack = CameraToggle.track?.videoTrack;

      if (videoTrack != null) {
        if (isBlurActive) {
          await videoTrack.stopProcessor();
        } else {
          await videoTrack.setProcessor(BackgroundBlur(10));
          await room.localParticipant.publishTrack(videoTrack);
        }
      }
    };

    React.useEffect(() => {
      if (eventUUID == null) {
        return;
      }

      const sub = seamlessClient.createPubSubSubscription({
        topic: `[${Tenant}]event.meeting/${eventUUID}/${current?.videoMeeting?.meetingPassword ?? ""
          }`,
        topicId: "meet.event-meeting",
        params: null,
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      sub.listen((message: "recording-started") => {
        setIsRecording(true);
      });
    }, [current?.videoMeeting?.meetingPassword, eventUUID]);

    const copyMeetingLink = React.useCallback(() => {
      if (isOpen) {
        return close();
      }

      if (current?.videoMeeting?.meetingPassword != null) {
        const meetingLink = `${getRootURL()}/meet/${"meetingUUID" in current.videoMeeting
          ? current.videoMeeting.meetingUUID
          : current.videoMeeting.eventUUID
          }/${current.videoMeeting.meetingPassword}`;
        void window.navigator.clipboard.writeText(meetingLink);
        open();
      }
    }, [close, current?.videoMeeting, isOpen, open]);

    const self = useSelfMaybe();

    const EventWrapper =
      eventUUID == null ? React.Fragment : MeetingEventProvider;

    if (!connected && !historicConnectedRef.current) {
      return <LoadingSpinnerFullView />;
    }

    if (props.spectator === true && tracks.length === 0) {
      return (
        <WaitingRoom started={props.started} />
      );
    }

    const isMobile = window.innerWidth < window.innerHeight;

    return (
      <EventWrapper eventUUID={eventUUID ?? ""} started={props.started}>
        {electronCaptureSources != null ?
          <Modal onClose={() => setElectronCaptureSources(null)}>
            <div style={{ padding: 20, display: "flex", flexDirection: "column", gap: 10 }}>
              <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                {electronCaptureSources.map((source) => (
                  <div key={source.id} style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 10, textAlign: "center", cursor: "pointer" }} onClick={() => startScreenShare({ sourceId: source.id })}>
                    {source.isEmpty ?
                      <div style={{ height: 200, width: 350, display: "flex", justifyContent: "center", alignItems: "center", background: "black" }}>
                        <FontAwesomeIcon icon={faHammerBrush} style={{ color: "white", fontSize: 75 }} />
                      </div>
                      :
                      <img src={source.thumbnailURL} style={{ height: 200, width: 200, objectFit: "contain" }} />
                    }
                    <div>{source.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
          : null}
        {!connected ? <DisconnectedIndicator /> : null}
        <PageContainer>
          <CXMenuContainer>
            <Card
              $color="background3"
              style={{
                width: 250,
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                Public Meeting Link has been copied to clipboard!
              </div>
            </Card>
          </CXMenuContainer>
          <div
            style={{
              height: 0,
              flexGrow: 1,
            }}
          >
            <MeetingCanvas>
              {focusTrack ? (
                isMobile ?
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ height: "25%" }}>
                      <GridTrackRenderer tracks={carouselTracks} />
                    </div>
                    <div
                      style={{
                        height: 0,
                        width: "100%",
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {focusTrack && (
                        <ParticipantContextIfNeeded
                          participant={focusTrack.participant}
                        >
                          <TrackTile track={focusTrack} isScreenShare />
                        </ParticipantContextIfNeeded>
                      )}
                    </div>
                  </div>
                  :
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <CarouselView tracks={carouselTracks} style={{ width: "20%" }}>
                      <TrackRefContext.Consumer>
                        {(track) =>
                          track && (
                            <TrackTile track={track} screenShareActive={true} />
                          )
                        }
                      </TrackRefContext.Consumer>
                    </CarouselView>
                    <div
                      style={{
                        height: "100%",
                        width: 0,
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {focusTrack && (
                        <ParticipantContextIfNeeded
                          participant={focusTrack.participant}
                        >
                          <TrackTile track={focusTrack} isScreenShare />
                        </ParticipantContextIfNeeded>
                      )}
                    </div>
                  </div>
              ) : (
                <GridTrackRenderer tracks={tracks} />
              )}
            </MeetingCanvas>
          </div>
          <ControlsBar isExternal={self == null}>
            <div style={{ width: isMobile ? "60%" : 0, flexGrow: 1, flexShrink: 0 }}>
              {self == null || eventUUID == null ? (
                <VideoMeetingLeftTitle>{current?.label}</VideoMeetingLeftTitle>
              ) : (
                <VideoMeetingLeftBar />
              )}
            </div>
            <ControlsBarButtons>
              {props.spectator !== true ? <>
                <MeetingButton
                  onClick={() => CameraToggle.toggle()}
                  icon={CameraToggle.enabled ? faVideo : faVideoSlash}
                  isLoading={CameraToggle.pending}
                  tooltip={
                    CameraToggle.enabled ? "Turn off camera" : "Turn on camera"
                  }
                  size={50}
                  secondary
                />
                <MeetingButton
                  onClick={() => MicrophoneToggle.toggle()}
                  icon={
                    MicrophoneToggle.enabled ? faMicrophone : faMicrophoneSlash
                  }
                  isLoading={MicrophoneToggle.pending}
                  tooltip={
                    MicrophoneToggle.enabled
                      ? "Turn off microphone"
                      : "Turn on microphone"
                  }
                  size={50}
                  secondary
                />
                <MeetingButton
                  onClick={ScreenShareToggle.enabled ? () => ScreenShareToggle.toggle() : (evt?: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => startScreenShare({
                    isBackground: evt?.nativeEvent.altKey === true
                  })}
                  icon={
                    ScreenShareToggle.enabled ? faDisplaySlash : ScreenshareIcon
                  }
                  isLoading={ScreenShareToggle.pending}
                  tooltip={
                    ScreenShareToggle.enabled
                      ? "Stop sharing screen"
                      : "Share screen"
                  }
                  size={50}
                  secondary
                />
                <MeetingButton
                  onClick={enableBackgroundBlur}
                  icon={isBlurActive ? faSquareUser : faSquareUserHollow}
                  awaitOnClickForLoading
                  tooltip={
                    isBlurActive ? "Stop background blur" : "Blur background"
                  }
                  size={50}
                  secondary
                />
              </> : null}
              {props.spectator === true && isMobile ? null :
                <MeetingButton
                  onClick={() => Disconnect.disconnect()}
                  icon={faTimes}
                  tooltip="Leave meeting"
                  size={50}
                />
              }
            </ControlsBarButtons>
            <div style={{ width: 0, flexGrow: 1, flexShrink: 0 }}>
              <ControlsBarRight style={{ justifyContent: "flex-end" }}>
                {self != null &&
                  eventUUID != null &&
                  !isRecording &&
                  IS_BETA_ENV ? (
                  <PillButton
                    onClick={startRecording}
                    label="Start Recording"
                  />
                ) : null}
                {isRecording ? <div>Recording</div> : null}
                {current?.videoMeeting?.meetingPassword != null && self != null && eventUUID != null ? (
                  <div ref={ref as React.RefObject<HTMLDivElement>}>
                    <PillButton
                      label="Copy Meeting Link"
                      onClick={copyMeetingLink}
                    />
                  </div>
                ) : null}
                <MeetingTimerWrapper />
              </ControlsBarRight>
            </div>
          </ControlsBar>
        </PageContainer>
      </EventWrapper>
    );
  },
);
