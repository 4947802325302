import React from "react";
import { styled } from "@hiyllo/ux/styled";

import * as GetSchedulingDetailsBP from "../../../blueprints/calendar/book-a-time/get-scheduling-details";
import * as BookSlotBP from "../../../blueprints/calendar/book-a-time/book-slot";
import { seamlessClient } from "../../../seamless-client";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import moment from "moment";
import { type BookATimeSlot } from "../../../types/calendar/book-a-time";
import { motion, AnimatePresence } from "framer-motion";
import { Input } from "@hiyllo/ux/input";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { Card } from "@hiyllo/ux/surface";
import { getRootURL } from "../../../platform/environment/get-root-url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@hiyllo/ux/button";
import { useShowAlert } from "@hiyllo/ux/dialogs";

const CornerImage = styled("img", ({ $isMobile }) => ({
  position: "fixed",
  bottom: 20,
  left: $isMobile ? 25 : 20,
  maxWidth: 200,
  maxHeight: 50,
  objectFit: "contain",
}));

const PageContainer = styled("div", ({ $theme, $isMobile }) => ({
  padding: 30,
  height: "calc(100% - 60px)",
  display: "flex",
  flexDirection: $isMobile ? "column" : "row",
  justifyContent: "center",
  alignItems: "center",
  background: $theme.background1,
  gap: 15,
  fontFamily: "hiyllo"
}));

const Header = styled("div", { fontSize: 28 });
const Label = styled("div", {
  fontSize: 16,
  marginTop: 10,
  marginBottom: 5,
});

// @ts-expect-error ---
const SlotItem = styled<"div", React.ComponentProps<typeof motion.div>>(
  motion.div,
  ({ $theme }) => ({
    background: $theme.buttonBackground,
    color: $theme.foreground,
    padding: 2,
    borderRadius: 10,
    overflow: "hidden",
    flexShrink: 0,
    cursor: "pointer",
    userSelect: "none",
  }),
);

const SlotItemInner = styled("div", ({ $theme }) => ({
  background: $theme.background1,
  borderRadius: 10,
  padding: 12.5,
  overflow: "hidden",
  textAlign: "center",
}));

const SlotOptions = styled("div", ({ $isMobile }) => ({
  maxHeight: $isMobile ? "60%" : "75%",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: 15,
}));

const InfoCard = styled("div", ({ $theme, $isMobile }) => ({
  background: $theme.background3,
  height: $isMobile ? "" : "calc(75% - 30px)",
  color: $theme.foreground,
  borderRadius: 15,
  overflow: "hidden",
  padding: 15,
}));

const ConfirmationIcon = styled("div", { fontSize: 52 });

const ConfirmationView = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 10,
});

const ScheduleViewInner = React.memo(function ScheduleViewInner(props: {
  token: string;
  data: GetSchedulingDetailsBP.Plug["response"];
}): JSX.Element {
  const bookSlotMutation =
    seamlessClient.useMutation<BookSlotBP.Plug>(BookSlotBP);
  const [selectedSlot, setSelectedSlot] = React.useState<BookATimeSlot | null>(
    null,
  );
  const [name, setName] = React.useState<string>("");
  const [emailAddress, setEmailAddress] = React.useState<string>("");
  const [bookingCompleted, setBookingCompleted] = React.useState<boolean>(
    new window.URLSearchParams(window.location.search).get("bc") === "1",
  );
  const [bypassDedup, setBypassDedup] = React.useState<boolean>(false);
  const bookSlotPendingRef = React.useRef(false);
  const showAlert = useShowAlert();

  const bookSlot = React.useCallback(() => {
    if (bookSlotPendingRef.current) return;
    if (selectedSlot == null) return;

    if (name.length < 3) {
      return alert("Please enter your name");
    }

    if (
      emailAddress.length < 5 ||
      !emailAddress.includes("@") ||
      !emailAddress.includes(".")
    ) {
      return alert("Please enter your email address");
    }

    bookSlotPendingRef.current = true;

    bookSlotMutation
      .call({
        token: props.token,
        slot: selectedSlot.timing,
        guest: {
          name,
          emailAddress,
        },
      })
      .then(() => {
        window.localStorage.setItem(
          "bookATimeLastBooked",
          selectedSlot.timing._computed.start.valueOf().toString(),
        );
        setBookingCompleted(true);
        const url = new URL(window.location.href);
        url.searchParams.set("bc", "1");
        window.history.replaceState("", "", url);
      }).catch(err => {
        bookSlotPendingRef.current = false;
        void showAlert({
          title: "Error booking appointment",
          message: err.description,
        });
      });
  }, [bookSlotMutation, emailAddress, name, props.token, selectedSlot, showAlert]);

  const bookATimeLastBooked = window.localStorage.getItem(
    "bookATimeLastBooked",
  );

  if (bookingCompleted) {
    const date = new Date(Number(bookATimeLastBooked as string));

    return (
      <Card $color="background3">
        <ConfirmationView>
          <ConfirmationIcon>
            <FontAwesomeIcon icon={faCheckCircle} />
          </ConfirmationIcon>
          Your appointment has been scheduled!
          <div>{moment(date).format("h:mm a, dddd MMM Do")}</div>
          <div
            style={{
              fontSize: 12,
              opacity: 0.8,
            }}
          >
            For the link to join your appointment, check your email
          </div>
        </ConfirmationView>
      </Card>
    );
  }

  if (bookATimeLastBooked != null && !bypassDedup) {
    const date = new Date(Number(bookATimeLastBooked));

    return (
      <Card $color="background3">
        <ConfirmationView>
          <ConfirmationIcon>
            <FontAwesomeIcon icon={faCheckCircle} />
          </ConfirmationIcon>
          <div
            style={{
              maxWidth: 300,
              textAlign: "center",
            }}
          >
            You&apos;ve already booked an appointment, are you sure you want to
            book another?
          </div>
          <div style={{ marginBottom: 10 }}>
            {moment(date).format("h:mm a, dddd MMM Do")}
          </div>
          <Button
            onClick={() => {
              setBypassDedup(true);
            }}
            label={"Yes, book another"}
          />
          <div
            style={{
              fontSize: 12,
              opacity: 0.8,
            }}
          >
            For the link to join your appointment, check your email
          </div>
        </ConfirmationView>
      </Card>
    );
  }

  if (selectedSlot != null) {
    return (
      <motion.div
        layoutId={selectedSlot.timing._computed.start.valueOf().toString()}
      >
        <InfoCard>
          <Header>
            {moment(selectedSlot.timing._computed.start).format("h:mm a")} -{" "}
            {moment(selectedSlot.timing._computed.end).format(
              "h:mm a, ddd MMM Do",
            )}
          </Header>
          <Label>Your Name</Label>
          <Input value={name} onChange={(evt) => setName(evt.target.value)} />

          <Label>Your Email Address</Label>
          <Input
            value={emailAddress}
            onChange={(evt) => setEmailAddress(evt.target.value)}
            type="email"
          />

          <div style={{ height: 20 }} />

          <Button
            isLoading={bookSlotMutation.isLoading}
            label="Book Appointment"
            onClick={bookSlot}
            autoWidth
          />
          <div style={{ height: 20 }} />
          <div>
            You&apos;ll receive an email with a link to join video conferencing
          </div>
        </InfoCard>
      </motion.div>
    );
  }

  return (
    <>
      <InfoCard>
        <div style={{ marginBottom: 5 }}>Book your appointment</div>
        <Header>{props.data.schedule.name}</Header>
        <div>{props.data.schedule.slotDuration} minutes</div>
        <div style={{ height: 20 }} />
        <div style={{ fontSize: 12 }}>
          All times shown are in your own timezone
        </div>
      </InfoCard>
      <SlotOptions>
        {props.data.slots.map((slot) => (
          <SlotItem
            layoutId={slot.timing._computed.start.valueOf().toString()}
            key={slot.timing._computed.start.valueOf()}
            onClick={() => setSelectedSlot(slot)}
          >
            <SlotItemInner>
              {moment(slot.timing._computed.start).format(
                "h:mm a, dddd MMM Do",
              )}
            </SlotItemInner>
          </SlotItem>
        ))}
      </SlotOptions>
    </>
  );
});

export const BookATimeScheduleView = React.memo(
  function BookATimeScheduleView(): JSX.Element {
    const token = useParams<{ token: string }>().token as string;
    const schedulingDetailsQuery =
      seamlessClient.useQuery<GetSchedulingDetailsBP.Plug>(
        GetSchedulingDetailsBP,
        { token },
      );

    return (
      <PageContainer>
        <AnimatePresence>
          {schedulingDetailsQuery.isError ? (
            <InfoCard>{schedulingDetailsQuery.error.description}</InfoCard>
          ) : schedulingDetailsQuery.isLoading ? (
            <LoadingSpinner />
          ) : (
            <ScheduleViewInner
              data={schedulingDetailsQuery.data}
              token={token}
            />
          )}
        </AnimatePresence>
        <CornerImage src={getRootURL() + "/ufplogo.png"} />
      </PageContainer>
    );
  },
);
