import React from "react";
import { BuiltInFolderEnum } from "../../../types/mail/organization/builtin-folders";

import * as ArchiveEmailBP from "../../../blueprints/mail/archive-email";
import { seamlessClient } from "../../../seamless-client";
import { styled } from "@hiyllo/ux/styled";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { type MailMessageSlim } from "../../../types/mail/message/message-slim";
import moment from "moment";
import { MailMessageDirection, MailMessageStatusEnum } from "../../../types/mail/message/message";
import { useNavigateTo } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faComputerClassic,
  faEmptySet,
  faExclamationCircle,
  faMegaphone,
  faSearch,
} from "@fortawesome/pro-light-svg-icons";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { useMailList } from "@hiyllo/omni-mail";
import { AnimatePresence, motion } from "framer-motion";
import { useDebounce } from "@hiyllo/ux/use-debounce";
import { NymblIcon } from "@hiyllo/icons/main";
import { useNavigate } from "@hiyllo/omni-continuity/main";
import { IS_BETA_ENV, IS_DEV } from "../../../platform/xp";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { BaseLoadingBar } from "../../../platform/loading/initial-loader";
import { Button, ButtonVariant } from "@hiyllo/ux/button";

const ListContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - 4px)",
  padding: 2,
  gap: 2,
  overflowY: "auto",
});

const MessageRowElement = styled<
  "div",
  // @ts-expect-error ---
  React.ComponentProps<typeof motion.div> & { read: boolean }
>(motion.div as unknown as "div", ({ $theme, read }) => ({
  background: read ? $theme.background2 : $theme.midground,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: 8,
  paddingRight: 8,
  fontFamily: "hiyllo",
  cursor: "pointer",
  flexShrink: 0,
  flexGrow: 0,
  gap: 8,
  overflow: "hidden",
}));

const UnreadDot = styled("div", ({ $theme }) => ({
  height: 8,
  width: 8,
  borderRadius: "50%",
  background: $theme.buttonBackground,
  flexShrink: 0,
}));

const HoverIcon = styled("div", ({ $theme }) => ({
  color: $theme.foregroundInactive,
  ":hover": {
    color: $theme.foreground,
  },
}));

const MessageListRow = React.memo(function MessageListRow(props: {
  message: MailMessageSlim;
  isInbox: boolean;
  onRemoved: () => void;
}): JSX.Element {
  const dateM = moment(props.message.date);
  const dateToday = dateM.format("YYYYMMDD") === moment().format("YYYYMMDD");
  const onClick = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "thread",
      threadUUID: props.message.threadUUID,
    },
  });
  const archiveEmailMutation =
    seamlessClient.useMutation<ArchiveEmailBP.Plug>(ArchiveEmailBP, {
      querySideEffects: [],
    });
  const archive = React.useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      void archiveEmailMutation.call({ uuid: props.message.uuid });
      props.onRemoved();
    },
    [archiveEmailMutation, props],
  );

  return (
    <MessageRowElement
      onClick={onClick}
      read={props.message.read}
      initial={{ height: 38 }}
      animate={{ height: 38 }}
      exit={{ height: 0 }}
      transition={{ duration: 0.15 }}
      id={`message-row-${props.message.uuid}`}
    >
      {props.isInbox ? (
        <div
          style={{
            width: 32,
            flexShrink: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <HoverIcon onClick={archive}>
            <FontAwesomeIcon icon={faArchive} />
          </HoverIcon>
        </div>
      ) : null}
      {props.message.status === MailMessageStatusEnum.outbox ?
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5, paddingRight: 10 }}>
          <LoadingSpinner />
          Sending...
        </div>
        : null}
      {props.message.status === MailMessageStatusEnum.bounced ?
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5, paddingRight: 10 }}>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <div>Send Failed</div>
        </div>
        : null}
      <div
        style={{
          flexShrink: 0,
          width: 180,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 4,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {!props.message.read ? <UnreadDot /> : null}
        {props.message.direction === MailMessageDirection.IN
          ? props.message.from.name || props.message.from.address
          : `To: ${props.message.to.length === 0
            ? "(No Recipient)"
            : props.message.to.join(", ")
          }`}
      </div>
      {props.message.nymblIsMarketing === true && IS_BETA_ENV ?
        <FontAwesomeIcon icon={faMegaphone} />
        : null}
      {props.message.nymblSummary != null ? (
        <div
          style={{
            flexBasis: "0%",
            flexGrow: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5
          }}
        >
          <div style={{ flexShrink: 0, height: 15 }}>
            <NymblIcon width={15} color="white" />
          </div>
          {props.message.nymblSummary}
        </div>
      ) : <>
        <div
          style={{
            flexShrink: 0,
            width: 320,
            fontWeight: !props.message.read ? "bold" : "normal",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {props.message.subject.length === 0
            ? "(No Subject)"
            : props.message.subject.slice(0, 42)}
        </div>
        <div
          style={{
            flexBasis: "0%",
            flexGrow: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {props.message.truncatedText}
        </div>
      </>}
      <div style={{ width: 120, flexShrink: 0, textAlign: "right" }}>
        {dateM.format(dateToday ? "h:mm a" : "MMM Do, YYYY")}
      </div>
    </MessageRowElement >
  );
});

const SearchBarArea = styled("div", {
  padding: 10,
  paddingBottom: 8
});

const SearchInputBarArea = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  color: $theme.foreground,
  borderRadius: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  padding: 15,
}));

const SearchInput = styled("input", ({ $theme }) => ({
  color: $theme.foreground,
  border: "none",
  background: "transparent",
  padding: 0,
  margin: 0,
  width: 0,
  flexGrow: 1,
  outline: "none",
  height: "100%",
}));

export const MailListView = React.memo(function MailListView(props: {
  folder: BuiltInFolderEnum | "all";
  search?: string;
}): JSX.Element {
  React.useEffect(() => {
    console.log('>>> render MailListView');
  }, []);
  const mailList = useMailList(props.folder, props.search);
  const debounce = useDebounce(1000, "ignore");
  const searchDebounce = useDebounce(500, "overwrite");
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const search = React.useCallback(() => {
    const query = searchInputRef.current?.value ?? "";

    if (query.trim().length === 0) {
      navigate({
        feature: Features.mail,
        params: {
          view: "mail",
          folder: props.folder,
        },
      });
    }
    else {
      navigate({
        feature: Features.mail,
        params: {
          view: "search",
          query,
        },
      });
    }
  }, [navigate, props.folder]);

  React.useEffect(() => {
    if (props.search === "" && searchInputRef.current != null) {
      searchInputRef.current.value = "";
    }
  }, [props.search]);

  const messages = mailList.messages;

  if (messages.length === 0 && (props.search == null || props.search === "")) {
    if (IS_DEV) {
      messages.push({
        uuid: '1',
        threadUUID: '1',
        from: { name: 'John Doe', address: 'test@hiyllo.io' },
        to: [],
        subject: 'Test Subject',
        read: false,
        truncatedText: 'Test Truncated Text',
        date: new Date(),
        direction: MailMessageDirection.IN,
        mailboxUUID: '1',
        status: MailMessageStatusEnum.received,
      }, {
        uuid: '2',
        threadUUID: '1',
        from: { name: 'John Doe', address: 'test@hiyllo.io' },
        to: [],
        subject: 'Test Subject',
        read: true,
        truncatedText: 'Test Truncated Text',
        date: new Date(),
        direction: MailMessageDirection.IN,
        mailboxUUID: '1',
        status: MailMessageStatusEnum.received,
      }, {
        uuid: '3',
        threadUUID: '1',
        from: { name: 'John Doe', address: 'test@hiyllo.io' },
        to: [],
        subject: 'Test Subject',
        read: true,
        truncatedText: 'Test Truncated Text',
        date: new Date(),
        direction: MailMessageDirection.IN,
        mailboxUUID: '1',
        status: MailMessageStatusEnum.received,
      });
    }
  }

  return (
    <ListContainer>
      <SearchBarArea>
        <label>
          <SearchInputBarArea>
            {mailList.loading && props.search != null && props.search.length > 0 ?
              <LoadingSpinner />
              :
              <FontAwesomeIcon icon={faSearch} />
            }
            <SearchInput
              _ref={searchInputRef}
              defaultValue={props.search}
              onChange={() => searchDebounce.debounce(search)}
              placeholder="Search mail..."
            />
          </SearchInputBarArea>
        </label>
      </SearchBarArea>
      {mailList.loading ?
        <BaseLoadingBar />
        : <div style={{ height: 2 }} />}
      <AnimatePresence>
        {(mailList.loading && messages.length === 0) ?
          <LoadingSpinnerFullView />
          : (messages.length === 0 && (props.search == null || props.search === "")) ?
            <EmptySplash icon={faEmptySet} label="No Mail Here" />
            : messages.length === 0 ?
              <EmptySplash icon={faSearch} label="No Mail Matching Search" />
              : messages.map((message) => (
                <MessageListRow
                  key={message.uuid}
                  message={message}
                  isInbox={props.folder === BuiltInFolderEnum.inbox}
                  onRemoved={() => {
                    mailList.pluckMessage(message.uuid);
                  }}
                />
              ))}
      </AnimatePresence>
      {mailList.loading && messages.length === 0 ? null : mailList.endReached ? (
        <div style={{ textAlign: "center", padding: 5 }}>
          <FontAwesomeIcon icon={faComputerClassic} />
          &nbsp;&nbsp;You&apos;ve reached the end.
        </div>
      ) : (
        <div style={{ padding: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            onClick={mailList.loadMore}
            label="Load Older"
            variant={ButtonVariant.narrow}
            isLoading={mailList.loading}
            isSecondary
            autoWidth
          />
        </div>
      )}
    </ListContainer>
  );
});
