import React from "react";
import fuzzysort from "fuzzysort";
import { Modal } from "@hiyllo/ux/modal";
import { Input } from "@hiyllo/ux/input";
import { QRCodeSVG } from "qrcode.react";
import { styled } from "@hiyllo/ux/styled";
import { HiylloIcon } from "@hiyllo/icons";
import { KeyCombinationHint } from "@hiyllo/ux/hotkeys";
import { Button, ButtonVariant } from "@hiyllo/ux/button";
import { useNavigate, useSelf } from "@hiyllo/omni-continuity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimateChangeInHeight, MouseInteraction } from "@hiyllo/ux/animation";
import { faBug, faCalendarPlus, faDownload, faEnvelopes, faHammer, faMobilePhone, faPortalEnter, faProjectDiagram, faUserPlus, faUsers, IconDefinition } from "@fortawesome/pro-light-svg-icons";

import { Electron } from "../../platform/electron";
import { Features } from "../../types/navigation/features";
import { faSlashForward } from "@fortawesome/pro-solid-svg-icons";
import { isDesktopApp } from "../../platform/environment/get-root-url";
import { CommandPaletteOptions } from "../../main/command-palette-provider";
import { BuiltInFolderEnum } from "../../types/mail/organization/builtin-folders";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 10,
  paddingLeft: 20,
  paddingRight: 20
});

const InnerContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 10
});

const SquareIcon = styled("div", ({ $theme }) => ({
  borderColor: $theme.foregroundInactive,
  color: $theme.foregroundInactive,
  borderWidth: 1,
  borderStyle: "solid",
  height: 25 - 2,
  width: 25 - 2,
  borderRadius: 25 / 4,
  fontSize: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const CommandContainer = styled<"div", { $highlighted: boolean }>("div", ({ $highlighted }) => ({
  display: "flex",
  width: 750 - 20 - 2.5,
  padding: 10,
  paddingLeft: 12.5,
  borderRadius: 10,
  backgroundColor: $highlighted ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0.1075)",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  height: 30,
  cursor: "pointer",
  userSelect: "none",
}));

const CommandIcon = styled("div", ({
  backgroundColor: "rgba(255,255,255,0.25)",
  backdropFilter: "blur(10px)",
  borderRadius: 5,
  height: 25,
  width: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 12.5
}));

const CommandLabel = styled("div", ({ $theme }) => ({
  fontSize: 20,
  color: $theme.foreground,
}));

export type Command = {
  icon: { fa: IconDefinition } | HiylloIcon;
  label: string;
  alias?: string;
  action: () => void;
  maintain?: boolean;
};

export const ForwardSlashIcon = React.memo(function ForwardSlashIcon(): JSX.Element {
  return (
    <SquareIcon>
      <FontAwesomeIcon icon={faSlashForward} />
    </SquareIcon>
  );
});

function useCommands(setShowMobileLogin: React.Dispatch<React.SetStateAction<boolean>>): Command[] {
  const navigate = useNavigate();

  return React.useMemo(() => {
    const retval = [
      {
        icon: { fa: faHammer },
        label: "Create Task",
        action: () => {
          navigate({
            feature: Features.tasks,
            params: {
              view: 'create-task'
            }
          });
        }
      },
      {
        icon: { fa: faProjectDiagram },
        label: "Create Project",
        action: () => {
          navigate({
            feature: Features.tasks,
            params: {
              view: 'create-new-project'
            }
          });
        }
      },
      {
        icon: { fa: faUserPlus },
        label: "Add User",
        alias: "invite user new user",
        action: () => {
          navigate({
            feature: Features.organization,
            params: {
              view: 'add-user'
            }
          });
        }
      },
      {
        icon: { fa: faUsers },
        label: "Create Team",
        alias: "new team",
        action: () => {
          navigate({
            feature: Features.organization,
            params: {
              view: 'create-team'
            }
          });
        }
      },
      {
        icon: { fa: faCalendarPlus },
        label: "Create Event",
        alias: "new event",
        action: () => {
          navigate({
            feature: Features.calendar,
            params: {
              view: 'create-event'
            }
          });
        }
      },
      {
        icon: { fa: faEnvelopes },
        label: "View Inbox",
        alias: "view mail",
        action: () => {
          navigate({
            feature: Features.mail,
            params: {
              view: 'mail',
              folder: BuiltInFolderEnum.inbox
            }
          });
        }
      },
      {
        icon: { fa: faMobilePhone },
        label: "Login on Mobile",
        alias: "mobile login",
        action: () => {
          setShowMobileLogin(true);
        },
        maintain: true
      },
    ];
    return retval;
  }, [navigate, setShowMobileLogin]);
}

const MobileLogin = React.memo(function MobileLogin(props: { onClose: () => void }): JSX.Element {
  const [code, setCode] = React.useState<"download" | "login">("login");
  const self = useSelf();

  return (
    <Modal onClose={props.onClose}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 50 }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 10, maxWidth: 400 }}>
          <QRCodeSVG
            value={code === "download" ? "https://download.hiyllo.work" : `${isDesktopApp() ? `https://${Electron.electronHost}` : window.location.origin}/native-login-v2?em=${self.email?.address}`}
            marginSize={2.5}
            level="H"
            width={200}
            height={200}
          />
        </div>
        <div style={{ maxWidth: 300 }}>
          {code === "download" ?
            <div>
              Open your phone&apos;s camera and scan the code to download the Hiyllo Work app. Once the app is installed, hit the button below.
            </div>
            :
            <div>
              Download the Hiyllo Work app then open your phone&apos;s camera and scan the code to login.
            </div>
          }
          <div style={{ height: 20 }} />
          {code === "download" ?
            <Button
              label={
                <>
                  <FontAwesomeIcon icon={faPortalEnter} />
                  Ready to login?
                </>
              }
              variant={ButtonVariant.narrow}
              onClick={() => {
                setCode("login");
              }}
              autoWidth
            />
            :
            <Button
              label={
                <>
                  <FontAwesomeIcon icon={faDownload} />
                  App not yet downloaded?
                </>
              }
              isSecondary
              variant={ButtonVariant.narrow}
              onClick={() => {
                setCode("download");
              }}
              autoWidth
            />
          }
        </div>
      </div>
    </Modal>
  );
});

export const CommandPalette = React.memo(function CommandPalette(props: {
  onClose: () => void;
  options: true | CommandPaletteOptions;
}): JSX.Element {
  const [query, setQuery] = React.useState("");
  const [index, setIndex] = React.useState(0);
  const [showMobileLogin, setShowMobileLogin] = React.useState(false);

  const commands = useCommands(setShowMobileLogin);

  const filteredCommands = React.useMemo(() => {
    const filteredCommands: Command[] = query === "" ? commands : fuzzysort.go(query, commands, {
      keys: ["label", "alias"],
    }).map((result) => result.obj);

    return filteredCommands.slice(0, 5);
  }, [commands, query]);

  return (
    <Modal onClose={props.onClose}>
      {showMobileLogin ?
        <MobileLogin onClose={() => setShowMobileLogin(false)} />
        : null}
      <AnimateChangeInHeight>
        <InnerContainer>
          <Container>
            {props.options !== true && props.options.openedViaButton ? <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", gap: 5 }}>
              <b>Poweruser tip!</b> Open faster with <KeyCombinationHint combination={["meta", "/"]} />
            </div> : null}
            <Input
              fullWidth
              autoFocus
              startEnhancer={
                <ForwardSlashIcon />
              }
              containerStyle={{ width: 750 - 7.5, padding: 5, paddingLeft: 2.5 }}
              inputStyle={{ paddingLeft: 10, fontSize: 20 }}
              placeholder="What do you want to do...?"
              onChangeValue={setQuery}
              value={query}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Escape") {
                  event.stopPropagation();
                  props.onClose();
                }
                if (event.key === "Enter") {
                  event.preventDefault();
                  event.stopPropagation();
                  if (filteredCommands[index].maintain !== true) props.onClose();
                  window.setTimeout(() => {
                    filteredCommands[index]?.action();
                  }, 50);
                }
                if (event.key === "ArrowDown") {
                  setIndex((index + 1) % filteredCommands.length);
                }
                if (event.key === "ArrowUp") {
                  setIndex((index - 1 + filteredCommands.length) % filteredCommands.length);
                }
              }}
            />

            {filteredCommands.map((command, i) => (
              <MouseInteraction key={command.label}>
                <CommandContainer
                  $highlighted={i === index}
                  onClick={() => {
                    if (command.maintain !== true) props.onClose();
                    command.action();
                  }}
                >
                  <CommandIcon>
                    {'fa' in command.icon ? <FontAwesomeIcon icon={command.icon.fa} /> : <command.icon width={15} color="white" />}
                  </CommandIcon>
                  <CommandLabel>{command.label}</CommandLabel>
                  {i === index ? <KeyCombinationHint size={12.5} combination={["Enter"]} /> : null}
                </CommandContainer>
              </MouseInteraction>
            ))}

            <div style={{ width: 750, fontSize: 12, opacity: 0.5 }}>Suggest new actions by clicking the <FontAwesomeIcon icon={faBug} /> icon in the dock</div>
          </Container>
        </InnerContainer>
      </AnimateChangeInHeight>
    </Modal>
  );
});